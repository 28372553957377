import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints, mode } from '@chakra-ui/theme-tools'

const fonts = {
  heading: `'Inter', sans-serif`,
  body: `'Inter', sans-serif`,
}

// 2. Add your color mode config
const config = {
  initialColorMode: 'dark',
  // useSystemColorMode: true,
}

const breakpoints = createBreakpoints({
  sm: '43em',
  md: '62em',
  lg: '82em',
})

const theme = extendTheme({
  config,
  fonts,
  // breakpoints,
  styles: {
    global: props => ({
      body: {
        bg: mode('#252525', 'none')(props),
      },
      colors: {
        black: '#16161D',
      },
    }),
  },
})

export default theme
