import React from 'react';
import {
  ChakraProvider,
  Box,
  Flex,
  Center,
  Text,
  Link,
  VStack,
  Image,
  Container,
  Divider,
  Spacer,
  SimpleGrid,
  Button,
} from '@chakra-ui/react';
import { Logo } from './Logo';
import logoShort from './Logo-short.svg'
import theme from './theme';
import heroImage from './images/bm-header-image.jpg'
import featureOneImage from './images/f1-gcm-million-dollar-theater.jpg'
import featureTwoImage from './images/f2-gcm-lower-level.jpg'

function App() {

  const scriptTag = document.createElement('script');
  scriptTag.src = 'https://ebs.boutiquemate.com/ebsButton/js/clientButtonEmbed.js'
  scriptTag.async = 'true'
  document.body.appendChild(scriptTag);

  return (
    <ChakraProvider theme={theme}>
      <Flex className='page' flexDir='column' maxW='1100px' w='100%' minH="100vh" m='auto'>
        <Center className='header' py={6}>
          <Logo w="250px" pointerEvents="none" />
        </Center>
        <Box className='content' flexGrow='1'>
          <Center 
            bgImage={heroImage}
            h='550px'
            bgPosition="center"
            bgRepeat="no-repeat"
            flexDir='column'
            color='white'
          >
            <Container textAlign='center' fontWeight='bold' fontSize={{base: 'large', md: 'x-large'}}>
              <Text>Boutique MateⓇ</Text>  
              <Text>Adaptive Booking/Operations System for</Text>
              <Text>Optimizing Data Products & Services</Text>
            </Container>
            <Container textAlign='center' p={2} mt={3}>
              <Text>
                Our modular booking & operations engine uses flex-feature technologies, 
                to optimize real estate facilities and equipment with adaptive intelligence  
              </Text>
            </Container>       
          </Center>

          <Divider py={4}/>

          <Center py={10} flexDir='column'>
            <Text color='white'>See the Boutique MateⓇ Booking Engine in action</Text>
            <Text fontSize='small' color='gray'>All transactions are in US Dollars ($)</Text>
          </Center>

          <SimpleGrid columns={{base: 1, md: 2}} spacing={10} color='white'>
            <Flex 
              bgColor='#494949' 
              flexDirection={{base: 'column', md: 'row'}} 
              borderRadius={6} 
              overflow='hidden'
              mx={{base: 4, md: 0}}
            >
              <Image w={{base: '100%', md: '40%'}} fit='cover' src={featureOneImage} />
              <Flex w={{base: '100%', md: '60%'}} flexDir='column' p={4}>
                <Text fontSize='large' textAlign='center' fontWeight='bold'>Grand Central Market <br /> Million Dollar Theater</Text>
                <Divider py={2}/>
                <Spacer />
                <Text py={4}>
                  Completed in 1918, the Million Dollar Theater welcomed some of the greatest performers in 
                  entertainment history and carries 104 years of tradition through today with film screenings 
                  and premieres, special events, live performances and panels.
                </Text>
                <Spacer />
                <Button className="bm-ebs-button" data-feature-id="279" data-use-bm-style="false">Book Now</Button>
              </Flex>
            </Flex>
            <Flex 
              bgColor='#494949' 
              flexDirection={{base: 'column', md: 'row'}} 
              borderRadius={6} 
              overflow='hidden'
              mx={{base: 4, md: 0}}
            >
              <Image w={{base: '100%', md: '40%'}} fit='cover' src={featureTwoImage} />
              <Flex w={{base: '100%', md: '60%'}} flexDir='column' p={4}>
                <Text fontSize='large' textAlign='center' fontWeight='bold'>Grand Central Market <br /> Lower Level</Text>
                <Divider py={2} />
                <Spacer />
                <Text py={4}>
                  The Lower Level venue is an industrious event space situated directly below Grand Central Market 
                  in DTLA. It features approximately 8,000 square feet of raw charm to design and devise space for 
                  any kind of event. 
                </Text>
                <Spacer />
                <Button className="bm-ebs-button" data-feature-id="286" data-use-bm-style="false">Book Now</Button>
              </Flex>
            </Flex>
          </SimpleGrid>

          <Divider py={4}/>
        </Box>
        <Box className='footer' py={4}>
          <Flex className='footer-rowOne' flexDir={{base: 'column', md: 'row'}}>
            <Flex className='rowOne-left' alignItems='center' color='lightgray' p={{base: 4, md: 0}}>
              <Image w="100px" src={logoShort} />
              <Text> 
              For Customer Service: <br/> 
              <Link href='mailto:concierge@boutiquemate.com'>concierge@boutiquemate.com</Link>
              </Text>
            </Flex>
            <Spacer/>
            <VStack className='rowOne-right' alignItems='right' color='lightgray' p={{base: 4, md: 0}}>
              <Link href='https://papi.boutiquemate.com/terms' isExternal>Refund & Dispute Policy</Link>
              <Link href='https://papi.boutiquemate.com/privacy-policy' isExternal>Consumer Data Privacy Policy</Link>
              <Link href='https://papi.boutiquemate.com/security' isExternal>Security capabilities and policy for <br/> the transmission of payment card details</Link>
            </VStack>
          </Flex>
          <Center className='footer-rowTwo' pt={6} fontSize='small' textAlign='center' color='gray'>Copyright © 2011-2024 Boutique MateⓇ <br/> All Rights Reserved</Center>
        </Box>
      </Flex>
    </ChakraProvider>
  );
}

export default App;
